/*
 *
 * Questions reducer
 *
 */

import md5 from 'blueimp-md5';
import { SIGN_OUT_USER } from 'containers/Auth/AuthRouter/constants';
import { DELETE_PATIENT_CASE_SUCCESS } from 'containers/GetCare/constants';
import { PATCH_QUESTION_SET_COMPLETE_FAILURE } from 'containers/Questions/constants';
import { keyBy } from 'lodash';
import {
  CHECK_SET_READY_FOR_COMPLETION_FALSE,
  CHECK_SET_READY_FOR_COMPLETION_TRUE,
  DEFAULT_ACTION,
  FETCH_QUESTION_CARDS,
  FETCH_QUESTION_CARDS_FAILURE,
  FETCH_QUESTION_CARDS_SUCCESS,
  FETCH_QUESTION_SETS,
  FETCH_QUESTION_SETS_FAILURE,
  FETCH_QUESTION_SETS_SUCCESS,
  GET_QUESTION_SET,
  GET_QUESTION_SET_FAILURE,
  GET_QUESTION_SET_SUCCESS,
  PATCH_QUESTION_CARD,
  PATCH_QUESTION_CARD_FAILURE,
  PATCH_QUESTION_CARD_SUCCESS,
  PATCH_QUESTION_SET,
  PATCH_QUESTION_SET_COMPLETE,
  PATCH_QUESTION_SET_FAILURE,
  PATCH_QUESTION_SET_SUCCESS,
  SET_CURRENT_QUESTION_SET_ID,
  SET_CURRENT_QUESTION_SET_TYPE,
  SET_QUESTION_SET_REDIRECT,
  UPDATE_QUESTION_SET_ANSWER,
} from './constants';

export const initialState = () => ({
  account: {
    loadingSet: false,
    loadingCards: false,
    cards: {},
    sets: {},
  },
  member: {
    loadingSet: false,
    loadingCards: false,
    cards: {},
    sets: {},
  },
  insurance: {
    loadingSet: false,
    loadingCards: false,
    cards: {},
    sets: {},
  },
  case: {
    loadingSet: false,
    loadingCards: false,
    cards: {},
    sets: {},
  },
  report_feedback: {
    loadingSet: false,
    loadingCards: false,
    cards: {},
    sets: {},
  },
  redirect: null,
});

function questionsReducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_QUESTION_SETS:
      return fetchQuestionSets(state, action);
    case FETCH_QUESTION_SETS_SUCCESS:
      return fetchQuestionSetsSuccess(state, action);
    case FETCH_QUESTION_SETS_FAILURE:
      return fetchQuestionSetsFailure(state, action);
    case GET_QUESTION_SET:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loadingSet: true,
        },
      };
    case GET_QUESTION_SET_SUCCESS:
      const set = action.payload[0] || action.payload || {}; //fetch or get
      const type = set.question_set_type;
      if (!set) return state;
      return {
        ...state,
        [type]: {
          ...state[type],
          loadingSet: false,
          sets: {
            ...(state[type]?.sets || {}),
            [set.id]: {
              ...set,
            },
          },
        },
      };
    case GET_QUESTION_SET_FAILURE:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          loadingSet: false,
        },
      };
    case DELETE_PATIENT_CASE_SUCCESS: {
      return {
        ...state,
        case: {
          loadingSet: false,
          loadingCards: false,
          cards: {},
          sets: {},
        },
      };
    }
    case PATCH_QUESTION_SET:
      return patchQuestionSet(state, action);
    case PATCH_QUESTION_SET_COMPLETE:
      return patchQuestionSet(state, action);
    case PATCH_QUESTION_SET_COMPLETE_FAILURE:
      return patchQuestionSetCompleteFailure(state, action);
    case PATCH_QUESTION_SET_SUCCESS:
      return patchQuestionSetSuccess(state, action);
    case PATCH_QUESTION_SET_FAILURE:
      return patchQuestionSetFailure(state, action);
    case SET_QUESTION_SET_REDIRECT:
      return setQuestionSetRedirect(state, action);

    case FETCH_QUESTION_CARDS:
      return fetchQuestionCards(state, action);
    case FETCH_QUESTION_CARDS_SUCCESS:
      return fetchQuestionCardsSuccess(state, action);
    case FETCH_QUESTION_CARDS_FAILURE:
      return fetchQuestionCardsFailure(state, action);
    case PATCH_QUESTION_CARD:
      return patchQuestionCard(state, action);
    case PATCH_QUESTION_CARD_SUCCESS:
      return patchQuestionCardSuccess(state, action);
    case PATCH_QUESTION_CARD_FAILURE:
      return patchQuestionCardFailure(state, action);
    case SET_CURRENT_QUESTION_SET_TYPE:
      return setCurrentQuestionSetType(state, action);
    case SET_CURRENT_QUESTION_SET_ID:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          currentSetId: action.payload.id,
        },
      };
    case CHECK_SET_READY_FOR_COMPLETION_TRUE:
      return checkQuestionSetReadyForCompletionSuccess(state, action);
    case CHECK_SET_READY_FOR_COMPLETION_FALSE:
      return checkQuestionSetReadyForCompletionFailure(state, action);
    case UPDATE_QUESTION_SET_ANSWER:
      return updateQuestionSetAnswer(state, action);
    case DEFAULT_ACTION:
      return state;
    case SIGN_OUT_USER:
      return initialState;
    default:
      return state;
  }
}

/* Helpers */

function updateAnswers(card, question_and_answer_id, answer_value) {
  const answers = card.answers;
  return answers.map((ans) => {
    if (ans.question_and_answer_id === question_and_answer_id) {
      ans.selected = true;
      ans.answer_value = answer_value;
    } else {
      ans.answer_value = '';
      ans.selected = false;
    }
    return ans;
  });
}

const answerValue = (answer) => {
  return md5(
    JSON.stringify({
      question_and_answer_id: answer.question_and_answer_id,
      answer_value: answer.answer_value,
    }),
  );
};

/* Sets */

const fetchQuestionSets = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingSet: true,
      loadingCards: true,
    },
  };
};

const fetchQuestionSetsSuccess = (state, action) => {
  const nState = {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingSet: false,
      sets: {
        ...state[action.payload.type].sets,
        ...keyBy(action.payload.data, 'id'),
      },
    },
  };
  // should be most recent
  if (action.payload.data.length) {
    nState[action.payload.type].currentSetId = action.payload.data[0]?.id;
  }
  return nState;
};

const fetchQuestionSetsFailure = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingSet: false,
      error: true,
    },
  };
};

const updateQuestionSetAnswer = (state, action) => {
  const { card, type } = action.payload;
  const answer = card.answers.find((ans) => ans.selected);
  const i = card.vertex;
  const j = answer?.next_vertex;
  const sets = state[type].sets;
  const currentSet = sets[state[type].currentSetId];
  const matrix = Array.from(currentSet.matrix);
  // Clear any previous answers for this vertex
  matrix[i] = new Array(matrix[i].length).fill('');
  matrix[i][j] = answerValue(answer);
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      sets: {
        ...sets,
        [currentSet.id]: {
          ...currentSet,
          matrix,
        },
      },
    },
  };
};

const patchQuestionSet = (state, action) => {
  const { id, type } = action.payload;
  return {
    ...state,
    [type]: {
      ...state[type],
      sets: {
        ...state[type].sets,
        [id]: {
          ...state[type].sets[id],
          loading: true,
          error: null,
        },
      },
    },
  };
};
const patchQuestionSetCompleteFailure = (state, action) => {
  const error = action.error;
  const { id, type } = action.payload;

  return {
    ...state,
    [type]: {
      ...state[type],
      sets: {
        ...state[type].sets,
        [id]: {
          ...state[type].sets[id],
          loading: false,
          error: error.message || 'There was an error updating your question set, please try again',
        },
      },
    },
  };
};

const patchQuestionSetSuccess = (state, action) => {
  const { id, question_set_type: type } = action.payload;
  return {
    ...state,
    [type]: {
      ...state[type],
      loadingSet: false,
      sets: {
        ...state[type].sets,
        [id]: {
          ...state[type].sets[id],
          ...action.payload,
          loading: false,
        },
      },
    },
  };
};

const setQuestionSetRedirect = (state, action) => {
  return {
    ...state,
    redirect: action.payload,
  };
};

const patchQuestionSetFailure = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingSet: false,
      sets: {
        ...state[action.payload.type].sets,
        [action.payload.id]: {
          error: action.error.message,
          loading: false,
        },
      },
    },
  };
};

const checkQuestionSetReadyForCompletionSuccess = (state, action) => {
  return {
    ...state,
    redirect: null,
    [action.payload.type]: {
      ...state[action.payload.type],
      sets: {
        ...state[action.payload.type].sets,
        [action.payload.setId]: {
          ...state[action.payload.type].sets[action.payload.setId],
          readyForCompletion: true,
        },
      },
    },
  };
};

const checkQuestionSetReadyForCompletionFailure = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      sets: {
        ...state[action.payload.type].sets,
        [action.payload.setId]: {
          ...state[action.payload.type].sets[action.payload.setId],
          readyForCompletion: false,
        },
      },
    },
  };
};

/* Cards */

const fetchQuestionCards = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingCards: true,
      cards: null,
    },
  };
};

const fetchQuestionCardsSuccess = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingCards: false,
      cards: {
        ...state[action.payload.type].cards,
        ...keyBy(action.payload.data, 'id'),
      },
    },
  };
};

const fetchQuestionCardsFailure = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      loadingCards: false,
      error: true,
      cards: null,
    },
  };
};

const patchQuestionCard = (state, action) => {
  const answers = updateAnswers(
    action.payload.card,
    action.payload.question_and_answer_id,
    action.payload.answer_value,
  );
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      cards: {
        ...state[action.payload.type].cards,
        [action.payload.card.id]: Object.assign(
          {},
          {
            ...state[action.payload.type].cards[action.payload.card.id],
            answers,
            error: null,
            loading: true,
          },
        ),
      },
    },
  };
};

const patchQuestionCardSuccess = (state, action) => {
  const { card, type } = action.payload;
  return {
    ...state,
    [type]: {
      ...state[type],
      cards: {
        ...state[type].cards,
        [card.id]: Object.assign({}, state[action.payload.type].cards[card.id], {
          loading: false,
          error: null,
        }),
      },
    },
  };
};

const patchQuestionCardFailure = (state, action) => {
  return {
    ...state,
    [action.payload.type]: {
      ...state[action.payload.type],
      cards: {
        ...state[action.payload.type].cards,
        [action.payload.card.id]: Object.assign({}, state[action.payload.type].cards[action.payload.card.id], {
          error: action.error?.message,
          loading: false,
        }),
      },
    },
  };
};

/* Other */

const setCurrentQuestionSetType = (state, action) => {
  return {
    ...state,
    currentType: action.payload.type,
    redirect: null,
  };
};

export default questionsReducer;
