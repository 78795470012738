/**
 *
 * Button.js
 *
 * Same as a material button, but with added tracking
 */

import React, { PropsWithChildren } from 'react';

export type ButtonProps = PropsWithChildren<{
  className?: string;
  fullWidth?: boolean;
  trackingId?: string;
  onClick?: () => void;
  onFocus?: () => void;
  onActive?: () => void;
}>;

import { Button } from '@oraleye/frontend-modules-core';
import { withTrackingId } from '@oraleye/frontend-modules-utils';
import styled from 'styled-components';

export const TrackedButton = styled(Button)`
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
`;

TrackedButton.defaultProps = {
  color: 'cta01',
  variant: 'contained',
};

const ButtonWithTracking = withTrackingId(Button);

export default ButtonWithTracking as React.FC<ButtonProps>;
