/**
 *
 * New Case - complete care or specific issue
 *
 */
import FormattedHelmetTitle from 'components/FormattedHelmetTitle';
import useHistoryPush from 'components/LinkWithQueryParams/useHistoryPush';
import Loader from 'components/Loader';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import Config from 'config';
import {
  COMPLETE_CARE_TEMPLATE_BASE_NAME,
  SCHEDULED_VIDEO_TEMPLATE_BASE_NAME,
  SPECIFIC_ISSUE_TEMPLATE_BASE_NAME,
} from 'constants/cases';
import {
  CREATE_COMPLETE_CARE_CASE,
  CREATE_SPECIFIC_ISSUE_CASE,
  CREATE_VIDEO_CASE,
  HOME_PAGE,
} from 'constants/pageIdentifiers';
import {
  fetchPatientCaseTemplatesAction,
  fetchPatientCasesAction,
  newPatientCaseAction,
} from 'containers/GetCare/actions';
import {
  selectNewPatientCaseError,
  selectPatientCaseTemplatesFetched,
  selectPatientCasesFetched,
  selectTemplate,
} from 'containers/GetCare/selectors';
import useMember from 'hooks/useMember';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import messages from './messages';

const paths = {
  [COMPLETE_CARE_TEMPLATE_BASE_NAME]: CREATE_COMPLETE_CARE_CASE,
  [SPECIFIC_ISSUE_TEMPLATE_BASE_NAME]: CREATE_SPECIFIC_ISSUE_CASE,
  [SCHEDULED_VIDEO_TEMPLATE_BASE_NAME]: CREATE_VIDEO_CASE,
};

const getTemplateBaseName = (pathname) => {
  if (location.pathname.includes(CREATE_COMPLETE_CARE_CASE)) return COMPLETE_CARE_TEMPLATE_BASE_NAME;
  if (location.pathname.includes(CREATE_SPECIFIC_ISSUE_CASE)) return SPECIFIC_ISSUE_TEMPLATE_BASE_NAME;
  if (location.pathname.includes(CREATE_VIDEO_CASE)) return SCHEDULED_VIDEO_TEMPLATE_BASE_NAME;
};

export const NewCase = ({ match, location }) => {
  const push = useHistoryPush();
  const dispatch = useDispatch();
  const patientCasesFetched = useSelector(selectPatientCasesFetched());
  const patientCaseTemplatesFetched = useSelector(selectPatientCaseTemplatesFetched());
  const templateBaseName = getTemplateBaseName(location.pathname);

  const pct = useSelector(selectTemplate(templateBaseName));

  const createCaseError = useSelector(selectNewPatientCaseError());

  useEffect(() => {
    if (!patientCasesFetched) {
      dispatch(fetchPatientCasesAction());
    }
  }, [dispatch, patientCasesFetched]);

  useEffect(() => {
    if (!patientCaseTemplatesFetched) {
      dispatch(fetchPatientCaseTemplatesAction());
    }
  }, [dispatch, patientCaseTemplatesFetched]);

  const [creatingCase, setCreatingCase] = useState(false);
  const member = useMember();

  useEffect(() => {
    if (!creatingCase && pct && patientCasesFetched) {
      dispatch(
        newPatientCaseAction({
          patient_case_template_id: pct.id,
          member_id: member.id,
          continueCase: false,
          push,
        }),
      );
      setCreatingCase(true);
    }
  }, [match, member, pct, patientCasesFetched, creatingCase, dispatch, push]);

  if (createCaseError) {
    return <RedirectWithQuery to={HOME_PAGE} />;
  }

  return (
    <>
      <FormattedHelmetTitle {...messages.documentTitle} values={{ company: Config.companyName }} />
      <Loader />
    </>
  );
};

NewCase.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
};
