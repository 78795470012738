/*
 *
 * Questions constants
 *
 */

export const DEFAULT_ACTION = 'app/Questions/DEFAULT_ACTION';
export const GET_QUESTION_SET = 'app/Questions/GET_QUESTION_SET';
export const GET_QUESTION_SET_SUCCESS = 'app/Questions/GET_QUESTION_SET_SUCCESS';
export const GET_QUESTION_SET_FAILURE = 'app/Questions/GET_QUESTION_SET_FAILURE';
export const PATCH_QUESTION_SET = 'app/Questions/PATCH_QUESTION_SET';
export const PATCH_QUESTION_SET_SUCCESS = 'app/Questions/PATCH_QUESTION_SET_SUCCESS';
export const PATCH_QUESTION_SET_FAILURE = 'app/Questions/PATCH_QUESTION_SET_FAILURE';
export const PATCH_QUESTION_SET_COMPLETE = 'app/Questions/PATCH_QUESTION_SET_COMPLETE';
export const PATCH_QUESTION_SET_COMPLETE_SUCCESS = 'app/Questions/PATCH_QUESTION_SET_COMPLETE_SUCCESS';
export const PATCH_QUESTION_SET_COMPLETE_FAILURE = 'app/Questions/PATCH_QUESTION_SET_COMPLETE_FAILURE';
export const SET_QUESTION_SET_REDIRECT = 'app/Questions/SET_QUESTION_SET_REDIRECT';
export const FETCH_QUESTION_SETS = 'app/Questions/FETCH_QUESTION_SETS';
export const FETCH_QUESTION_SETS_SUCCESS = 'app/Questions/FETCH_QUESTION_SETS_SUCCESS';
export const FETCH_QUESTION_SETS_FAILURE = 'app/Questions/FETCH_QUESTION_SETS_FAILURE';
export const FETCH_QUESTION_CARDS = 'app/Questions/FETCH_QUESTION_CARDS';
export const FETCH_QUESTION_CARDS_SUCCESS = 'app/Questions/FETCH_QUESTION_CARDS_SUCCESS';
export const FETCH_QUESTION_CARDS_FAILURE = 'app/Questions/FETCH_QUESTION_CARDS_FAILURE';
export const PATCH_QUESTION_CARD = 'app/Questions/PATCH_QUESTION_CARD';
export const PATCH_QUESTION_CARD_SUCCESS = 'app/Questions/PATCH_QUESTION_CARD_SUCCESS';
export const PATCH_QUESTION_CARD_FAILURE = 'app/Questions/PATCH_QUESTION_CARD_FAILURE';
export const CHECK_SET_COMPLETE = 'app/Questions/CHECK_SET_COMPLETE';
export const CHECK_SET_READY_FOR_COMPLETION = 'app/Questions/CHECK_SET_READY_FOR_COMPLETION';
export const CHECK_SET_READY_FOR_COMPLETION_TRUE = 'app/Questions/CHECK_SET_READY_FOR_COMPLETION_TRUE';
export const CHECK_SET_READY_FOR_COMPLETION_FALSE = 'app/Questions/CHECK_SET_READY_FOR_COMPLETION_FALSE';
export const CHECK_SET_COMPLETE_SUCCESS = 'app/Questions/CHECK_SET_COMPLETE_SUCCESS';
export const CHECK_SET_COMPLETE_FAILURE = 'app/Questions/CHECK_SET_COMPLETE_FAILURE';
export const SET_CURRENT_QUESTION_SET_TYPE = 'app/Questions/SET_CURRENT_QUESTION_SET_TYPE';
export const SET_CURRENT_QUESTION_SET_ID = 'app/Questions/SET_CURRENT_QUESTION_SET_ID';
export const UPDATE_QUESTION_SET_ANSWER = 'app/Questions/UPDATE_QUESTION_SET_ANSWER';
export const UPDATE_QUESTION_SET_ANSWER_SUCCESS = 'app/Questions/UPDATE_QUESTION_SET_ANSWER_SUCCESS';
export const UPDATE_QUESTION_SET_ANSWER_FAILURE = 'app/Questions/UPDATE_QUESTION_SET_ANSWER_FAILURE';

export const ACCOUNT_QUESTION_SET_TYPE = 'account';
export const MEDICAL_QUESTION_SET_TYPE = 'member';
export const MEMBER_QUESTION_SET_TYPE = 'member';
export const INSURANCE_QUESTION_SET_TYPE = 'insurance';
export const CASE_QUESTION_SET_TYPE = 'case';
export const REPORT_FEEDBACK_SET_TYPE = 'report_feedback';
