// -- Auth --
// quip
export const QUIP_LOGIN_PAGE = '/quip-login';
export const QUIP_SIGNUP_PAGE = '/quip-signup';

// non-quip
export const CREATE_ACCOUNT_PAGE = '/create-account';
export const LOGIN_PAGE = '/login';
export const LOGOUT_PAGE = '/logout';
export const RESET_PAGE = '/reset';
export const RESET_TOKEN_PAGE = `${RESET_PAGE}/:token`;

// -- Setup --
export const SETUP_PAGE = '/setup';
export const SETUP_TYPE_PAGE = `${SETUP_PAGE}/:type`;

// -- Home --
export const HOME_PAGE = '/';

// -- Get Care --
export const GET_CARE_PAGE = '/get-care';

// Paths that help make up URLs of pages in the application
export const VIDEO_PATH = 'video';
export const VIDEO_CALL_PAGE = `${VIDEO_PATH}/:roomId`;
export const PHARMACY_SELECTION = 'pharmacy-selection';
export const PHARMACY = 'pharmacy';
export const MEDICAL_INFORMATION = 'medical-information';
export const CREATE_VIDEO_CALL_PAGE_NEW = 'create-video-consultation';
export const CREATE_VIDEO_CALL_PAGE = 'create-video-call';

// -- Consultation --
const caseId = ':id';
const photoCategoryId = ':photoCategoryId';

// case route
export const CONSULTATION_BASE = `/case/${caseId}`;

// common
export const CONSULTATION_QUESTIONS_PAGE = 'questions';
export const CONSULTATION_ACCOUNT_PAGE = 'account';
export const CONSULTATION_MEMBER_PAGE = 'member';
export const CONSULTATION_MEDICAL_INFORMATION_PAGE = 'medical-information';
export const CONSULTATION_PHARMACY_PAGE = 'pharmacy';
export const CONSULTATION_PHARMACY_RESULTS_PAGE = 'results'; //subroute

// schedule video
export const CONSULTATION_SCHEDULE_DATE = `consultation-schedule-date`;
export const CONSULTATION_SCHEDULED_VIDEO_CASE_SUBMITTED = `consultation-scheduled-submitted`;
export const CONSULTATION_SCHEDULE_SELECTED_VIEW = `consultation-schedule-selected`; // the page after the date/time is selected
export const CONSULTATION_SCHEDULED_CASE_MISSED = `consultation-scheduled-missed`; // the page after the date/time is selected

//async
export const CONSULTATION_PHOTOS_PAGE = 'photos';
export const CONSULTATION_PHOTOS_INSTRUCTION_PAGE = 'instructions';
export const CONSULTATION_PHOTOS_SELECTION_PAGE = 'selection';
export const CONSULTATION_TAKE_PHOTO_PAGE = `take/${photoCategoryId}`;
export const CONSULTATION_SUBMITTED = 'case-submitted';
export const CONSULTATION_SUBMITTING = 'case-submitting';

// video
export const CONSULTATION_CANCEL = 'cancel';
export const CONSULTATION_VIDEO_CALL_PAGE = 'consultation-video';
export const CONSULTATION_VIDEO_CASE_COMPLETED = 'video-completed';

// checkout
export const CONSULTATION_CHECKOUT = 'checkout';
export const CONSULTATION_CHECKOUT_PAYMENT_PAGE = 'pay'; //subroute
export const CONSULTATION_CHECKOUT_USE_BENEFITS_PAGE = 'pay-with-benefits'; //subroute
export const CONSULTATION_CHECKOUT_HOW_TO_PAY_PAGE = 'how-to-pay'; //subroute
export const CONSULTATION_CHECKOUT_ADD_INSURANCE_PAGE = 'add-insurance'; //subroute

// case landing pages
export const TRIAGE_LANDING_PAGE = '/triage';
export const FOLLOW_UP_LANDING_PAGE = '/follow_up';
export const PATIENT_CASE_LANDING_PAGE = '/start_case/:id';

// case start routes
export const CREATE_TRIAGE_CASE = '/triage/new';
export const CREATE_COMPLETE_CARE_CASE = '/complete_care/new';
export const CREATE_SPECIFIC_ISSUE_CASE = '/specific_issue/new';
export const CREATE_VIDEO_CASE = '/video_case/new';
export const CREATE_PATIENT_CASE_ROUTE = '/start_patient_case/:id'; // id is patient case template id

// -- Records --
export const RECORDS_PAGE = '/records';
export const REPORT_PAGE = '/report/:id';
export const PRESCRIPTION_PAGE = '/prescription/:id';

export const NOT_FOUND_PAGE = '/not_found';
export const URL_REDIRECT_ROUTE = '/d/:id';
export const MEMBER_PROFILE = '/profile';
export const VIDEO_PREFERENCES_PAGE = '/video-preferences';

// -- Helpers --
export const getConsultationBaseUrl = (patientCase) => CONSULTATION_BASE.replace(':id', patientCase.id);

export const getConsultationFullPathUrl = (patientCaseId, path) =>
  `${CONSULTATION_BASE.replace(':id', patientCaseId)}/${path}`;

export const getVideoRouteUrl = (patientCase) =>
  `${CONSULTATION_BASE.replace(':id', patientCase.id)}/${CONSULTATION_VIDEO_CALL_PAGE}`;

export const getCheckoutPaymentRouteUrl = (patientCaseId) =>
  `${CONSULTATION_BASE.replace(':id', patientCaseId)}/${CONSULTATION_CHECKOUT}/${CONSULTATION_CHECKOUT_PAYMENT_PAGE}`;
