import { Button, Select } from '@oraleye/frontend-modules-components';
import Loader from 'components/Loader';
import { ReactComponent as ExclamationIcon } from 'images/icon-error-exclamation-circle.svg';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { setSelectedDevice } from './helpers';

const VideoInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  video {
    width: 100%;
    // flip video horizontally
    transform: scaleX(-1);
  }
`;

export const VideoInputTest = ({ askForPermissions, devices, permission, selectedDeviceId: sid, onReady }) => {
  const videoStream = useRef();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(sid);

  useEffect(() => {
    setSelectedDeviceId(sid);
  }, [sid]);

  const streamInit = useRef(false);
  const handleStartVideo = useCallback(async () => {
    try {
      if (!streamInit.current) {
        streamInit.current = true;
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: selectedDeviceId },
        });
        videoStream.current = stream;
        setVideoPlaying(true);
      }
    } catch (e) {
      streamInit.current = false;
      console.error(e);
    }
  }, [selectedDeviceId]);

  const handleStopVideo = useCallback(() => {
    if (videoStream.current) {
      videoStream.current.getTracks().forEach((track) => {
        track.stop();
      });
      videoStream.current = null;
    }
    setVideoPlaying(false);
  }, []);

  const handleVideoInputChange = useCallback((event) => {
    setSelectedDevice('VideoInput', event.target.value);
    setSelectedDeviceId(event.target.value);
  }, []);

  const videoInputDeviceOptions = useMemo(
    () =>
      devices.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })),
    [devices],
  );

  // Init
  useEffect(() => {
    if (permission?.state === 'granted') {
      handleStartVideo();
    }
    //cleanup - stop video
    return () => {
      handleStopVideo();
    };
  }, [handleStartVideo, handleStopVideo, permission]);

  useEffect(() => {
    if ((permission?.state === 'granted' && videoPlaying) || permission?.state === 'denied') {
      onReady?.();
    }
  }, [permission, onReady, videoPlaying]);

  if (!permission) {
    return <Loader />;
  }

  return (
    <VideoInputWrapper>
      {permission.state === 'prompt' && (
        <div>
          <p>Video permission is not granted.</p>
          <Button onClick={askForPermissions}>Grant Permission</Button>
        </div>
      )}

      {permission.state === 'denied' && (
        <div style={{ textAlign: 'center' }}>
          <span>
            <ExclamationIcon />
          </span>
          <h3>Video permission is denied</h3>
          <p>
            You may have to grant permissions manually if you did not see a popup on this page. Click the information
            icon in the address bar, or check your browsers settings to see video permissions.
          </p>
        </div>
      )}

      {permission.state === 'granted' && videoStream.current && (
        <div>
          <Form
            onSubmit={() => {}}
            render={() => (
              <Select
                value={selectedDeviceId}
                onChange={handleVideoInputChange}
                name="videoInputDevice"
                options={videoInputDeviceOptions}
                placeholder="Select a video input device"
                initialValue={selectedDeviceId}
                trackingId={'video-input-device'}
              />
            )}
          />

          <div>
            {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
            <video
              controls={false}
              ref={(video) => {
                console.log('redred');
                if (video) {
                  video.srcObject = videoStream.current;
                }
              }}
              autoPlay
              playsInline
              muted
            />
          </div>
        </div>
      )}
      {permission.state === 'granted' && !videoStream.current && <Loader />}
    </VideoInputWrapper>
  );
};
