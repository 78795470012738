import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FOOTER_HEIGHT, FOOTER_HEIGHT_LG } from 'components/Footer';
import { NAVBAR_HEIGHT_LG } from 'components/Header/NavBar';
import Config from 'config';
import styled, { css } from 'styled-components';

export const OpenButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  color: ${() => (Config.isQuip || Config.isDelta ? 'var(--neutral04)' : 'var(--primary02)')};
  display: flex;
  font-size: 27px;
  outline: none;
  padding-left: 5px;
`;

export const MainContainer = styled.div`
  min-height: calc(100vh - ${NAVBAR_HEIGHT_LG}px);
  min-height: calc(--webkit-fill-available - ${NAVBAR_HEIGHT_LG}px);
`;

export const MainContentContainer = styled.div`
  min-height: calc(100vh - ${NAVBAR_HEIGHT_LG}px);
  min-height: calc(--webkit-fill-available - ${NAVBAR_HEIGHT_LG}px);
`;

export const LogoDiv = styled.div`
  img {
    min-height: 30px;
    display: block;
    margin: 0 auto;
    padding: 0;
    max-width: 75%;
  }
  ${({ theme }) =>
    theme.breakpoints.minSm(css`
      img {
        height: ${NAVBAR_HEIGHT_LG - 16}px;
      }
    `)};
`;

export const PageStretch = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ hasHeader }) => `
  calc(100vh - ${hasHeader ? NAVBAR_HEIGHT_LG : 0}px - ${FOOTER_HEIGHT}px);
  calc(--webkit-fill-available - ${hasHeader ? NAVBAR_HEIGHT_LG : 0}px - ${FOOTER_HEIGHT}px);
  `};
  ${({ theme, hasHeader }) =>
    theme.breakpoints.minMd(css`
      min-height: calc(100vh - ${hasHeader ? NAVBAR_HEIGHT_LG : 0}px - ${FOOTER_HEIGHT_LG}px);
      min-height: calc(--webkit-fill-available - ${hasHeader ? NAVBAR_HEIGHT_LG : 0}px - ${FOOTER_HEIGHT_LG}px);
    `)};
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  width: 0.875em !important;
`;
