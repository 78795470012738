import { errorNotificationAction } from '@oraleye/frontend-modules-utils';
import { LD_FEATURE_PRESCRIPTIONS } from 'constants/launchDarklyFlags';
import { CONSULTATION_BASE } from 'constants/pageIdentifiers';
import { patchMemberMedicalInformation } from 'containers/Auth/AuthRouter/actions';
import { FETCH_PATIENT_CASE_TEMPLATE, PATCH_PATIENT_CASE_STATUS } from 'containers/GetCare/constants';
import { updatePatientCaseCall } from 'containers/Photos/saga';
import { PATCH_QUESTION_SET_COMPLETE_SUCCESS } from 'containers/Questions/constants';
import { uniq } from 'lodash';
import { stringify } from 'query-string';
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { getIncompleteCases } from 'utils/caseHelpers';
import request from 'utils/request';
import {
  createPatientCaseActionFailure,
  createPatientCaseActionSuccess,
  deletePatientCaseAction,
  deletePatientCaseActionFailure,
  deletePatientCaseActionSuccess,
  fetchPatientCaseTemplatesFailure,
  fetchPatientCaseTemplatesSuccess,
  fetchPatientCasesFailure,
  fetchPatientCasesSuccess,
  fetchRegionsFailureAction,
  fetchRegionsSuccessAction,
  getPatientCase as getPatientCaseAction,
  getPatientCaseFailure,
  getPatientCaseSuccess,
  getPatientCasesFailure,
  getPatientCasesSuccess,
  patchMembersStateFailureAction,
  patchMembersStateSuccessAction,
  patchPatientCaseStatusFailureAction,
  patchPatientCaseStatusSuccessAction,
} from './actions';
import {
  CREATE_PATIENT_CASE,
  DELETE_PATIENT_CASE,
  DELETE_PATIENT_CASE_FAILURE,
  DELETE_PATIENT_CASE_SUCCESS,
  FETCH_PATIENT_CASES,
  FETCH_PATIENT_CASE_TEMPLATES,
  FETCH_REGIONS,
  GET_PATIENT_CASE,
  GET_PATIENT_CASES,
  NEW_PATIENT_CASE,
  PATCH_MEMBERS_STATE,
  PATIENT_CASES_ENDPOINT,
} from './constants';
import { selectPatientCases } from './selectors';

function* fetchPatientCases() {
  const method = 'GET';
  const endpoint = `${PATIENT_CASES_ENDPOINT}?limit=100`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchPatientCasesSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(fetchPatientCasesFailure(e));
  }
}

function* getPatientCase({ payload }) {
  if (!payload.id) return;

  const method = 'GET';
  const endpoint = `${PATIENT_CASES_ENDPOINT}/${payload.id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(getPatientCaseSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getPatientCaseFailure(e, payload));
  }
}

function* getPatientCases({ payload }) {
  const { ids } = payload;
  const cases = {};

  if (!ids.length) {
    yield put(getPatientCasesSuccess(Object.values(cases)));
    return;
  }
  const idsQuery = `?id[]=${ids.join('&id[]=')}&limit=100`;
  const method = 'GET';
  const endpoint = `${PATIENT_CASES_ENDPOINT}${idsQuery}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(getPatientCasesSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getPatientCasesFailure(e));
  }
}

/**
 * DELETE a patient case
 * Used when incomplete
 * @param payload
 * @return {IterableIterator<PutEffect<{payload, type, error}>|CallEffect|PutEffect<{payload, type}>>}
 */
function* deletePatientCase({ payload }) {
  const { id } = payload;
  const method = 'DELETE';
  const endpoint = `${PATIENT_CASES_ENDPOINT}/${id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };

  try {
    yield call(request, endpoint, requestData);
    yield put(deletePatientCaseActionSuccess(payload));
  } catch (e) {
    console.error(e);
    yield put(deletePatientCaseActionFailure(e, payload));
    yield put(deletePatientCaseActionSuccess(payload));
  }
}

function* createPatientCaseCall(payload) {
  const { company_case_type_id, patient_case_template_id, member_id } = payload;
  const method = 'POST';
  const endpoint = `${PATIENT_CASES_ENDPOINT}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
    body: JSON.stringify({
      company_case_type_id,
      patient_case_template_id,
      member_id,
    }),
  };
  return yield call(request, endpoint, requestData);
}

/**
 * Joins a patient case
 * If a case is incomplete, delete it and start a new one
 * If it is ongoing, redirect to that page
 * Otherwise, create a new case
 * @param payload {{company_case_type_id, member_id, template_base_name, patient_case_template_id, continueCase, push}}
 */
function* newPatientCase({ payload }) {
  if (payload.continueCase) {
    payload.push(`${CONSULTATION_BASE.replace(':id', payload.continueCase)}`);

    return;
  }

  try {
    const patientCases = yield select(selectPatientCases());
    const incompleteCases = getIncompleteCases(
      patientCases,
      payload.patient_case_template_id,
      payload.template_base_name,
    );

    if (incompleteCases?.length) {
      try {
        yield all(incompleteCases.map((incompleteCase) => put(deletePatientCaseAction(incompleteCase))));
        yield race([take(DELETE_PATIENT_CASE_SUCCESS), take(DELETE_PATIENT_CASE_FAILURE)]);
      } catch (e) {
        // already deleted
      }
    }
    const newCase = yield createPatientCaseCall(payload);
    yield put(createPatientCaseActionSuccess(newCase));

    // Patch member medical information to false so the step shows up for
    // returning users. Only for prescriptions companies.
    const flags = yield window.ldClient?.allFlags();
    if (flags?.[LD_FEATURE_PRESCRIPTIONS]) {
      yield put(
        patchMemberMedicalInformation({
          body: {
            medications_complete: false,
            allergies_complete: false,
            pharmacies_complete: false,
          },
          memberId: payload.member_id,
        }),
      );
    }

    if (payload.redirectUrl) {
      payload.push(payload.redirectUrl.replace(':id', newCase.id));
    } else {
      payload.push(CONSULTATION_BASE.replace(':id', newCase.id));
    }
  } catch (e) {
    console.error(e);
    yield put(errorNotificationAction({ title: 'Failed to create new case' }));
    yield put(createPatientCaseActionFailure(e, payload));
  }
}

/**
 * POST to patient cases
 * @param payload
 * @return {IterableIterator<PutEffect<{payload, type, error}>|CallEffect|PutEffect<{payload, type}>>}
 */
function* createPatientCase({ payload }) {
  try {
    const response = yield createPatientCaseCall(payload);
    yield put(createPatientCaseActionSuccess(response));
  } catch (e) {
    yield put(
      errorNotificationAction({
        title:
          'There was an error starting your video call. If this problem persists, please use the Intercom chat below',
      }),
    );
    yield put(createPatientCaseActionFailure(e, payload));
  }
}

/**
 * @param payload
 * @return {IterableIterator<PutEffect<{payload, type}>>}
 */
function* onPatchQuestionSetComplete({ payload, ...args }) {
  if (payload?.patient_case_id) {
    yield put(getPatientCaseAction({ id: payload.patient_case_id }));
  }
}

/**
 * Get available case types
 * Note that we define a video call as one that has 'video_call' in the required stages
 * @return {IterableIterator<CallEffect|PutEffect<{payload, type}>>}
 */

function* fetchPatientCaseTemplates({ payload = {} }) {
  const { type, ids, template_type } = payload;
  const method = 'GET';
  let endpoint = `v3/patient_case_templates?sort=created_at:DESC`;
  if (ids?.length) {
    endpoint = `${endpoint}&${stringify(
      { patient_case_template_id: uniq(ids) },
      { arrayFormat: 'bracket' },
    )}&limit=150`;
  }
  if (type) {
    endpoint = `${endpoint}&template_base_name=${type}`;
  }
  if (template_type) {
    endpoint = `${endpoint}&template_type=${template_type}`;
  }

  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchPatientCaseTemplatesSuccess(response));
  } catch (e) {
    yield put(fetchPatientCaseTemplatesFailure(e, payload));
  }
}

function* fetchPatientCaseTemplate({ payload = {} }) {
  const { id } = payload;
  if (!id) {
    yield put(fetchPatientCaseTemplatesFailure(new Error('No template id provided'), payload));
  }
  const method = 'GET';
  const endpoint = `v3/patient_case_templates/${id}`;
  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchPatientCaseTemplatesSuccess([response]));
  } catch (e) {
    yield put(fetchPatientCaseTemplatesFailure('404', payload));
  }
}

function* fetchRegions() {
  const method = 'GET';
  const endpoint = 'v3/regions?country[]=Guam&country[]=US&country[]=Ireland&limit=60&sort=name:ASC';
  const requestHeaders = new Headers();
  const requestData = {
    method,
    headers: requestHeaders,
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(fetchRegionsSuccessAction(response));
  } catch (e) {
    yield put(fetchRegionsFailureAction(e));
  }
}

function* patchMemberState({ payload }) {
  const { memberId, regionId } = payload;
  const method = 'PATCH';
  const endpoint = `v3/members/${memberId}`;
  const requestHeaders = new Headers();
  const body = {
    region_id: regionId,
    timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timezone_offset: new Date().getTimezoneOffset(),
  };
  const requestData = {
    method,
    headers: requestHeaders,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchMembersStateSuccessAction(response));
  } catch (e) {
    yield put(patchMembersStateFailureAction(e));
  }
}
function* patchPatientCaseStatus({ payload }) {
  const { patientCaseId, caseStatus } = payload;
  try {
    const patientCase = yield updatePatientCaseCall({
      patientCaseId,
      caseStatus,
    });
    yield put(patchPatientCaseStatusSuccessAction(patientCase));
  } catch (e) {
    console.error(e);
    yield put(
      errorNotificationAction({
        title: 'Error updating case. Please contact support.',
      }),
    );
    yield put(patchPatientCaseStatusFailureAction(e));
  }
}

export default function* getCareSaga() {
  yield takeLatest(FETCH_PATIENT_CASES, fetchPatientCases);
  yield takeLatest(GET_PATIENT_CASE, getPatientCase);
  yield takeLatest(GET_PATIENT_CASES, getPatientCases);
  yield takeLatest(NEW_PATIENT_CASE, newPatientCase);
  yield takeLatest(CREATE_PATIENT_CASE, createPatientCase);
  yield takeLatest(DELETE_PATIENT_CASE, deletePatientCase);
  yield takeLatest(PATCH_QUESTION_SET_COMPLETE_SUCCESS, onPatchQuestionSetComplete);
  yield takeLatest(FETCH_PATIENT_CASE_TEMPLATES, fetchPatientCaseTemplates);
  yield takeLatest(FETCH_PATIENT_CASE_TEMPLATE, fetchPatientCaseTemplate);
  yield takeLatest(FETCH_REGIONS, fetchRegions);
  yield takeLatest(PATCH_MEMBERS_STATE, patchMemberState);
  yield takeLatest(PATCH_PATIENT_CASE_STATUS, patchPatientCaseStatus);
}
