/**
 *
 * LaunchDarklyUpdater
 *
 */
import useMember from 'hooks/useMember';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createLDUser } from 'services/LaunchDarkly/user';
import { selectCompanyCopy } from './selectors';

export const LaunchDarklyUpdater = ({ children }) => {
  const ldClient = useLDClient();
  const companyCopy = useSelector(selectCompanyCopy());

  const member = useMember();

  useEffect(() => {
    window.ldClient = ldClient;
  }, [ldClient]);

  useEffect(() => {
    if (member) {
      // set up a new user
      const ldUser = createLDUser(companyCopy, member?.company_id);
      const currentUser = ldClient.getContext();

      // if the user has changed, update the user
      if (currentUser?.key !== ldUser.key) {
        ldClient.identify(ldUser);
      }
    }
  }, [companyCopy, ldClient, member]);

  return children;
};

LaunchDarklyUpdater.propTypes = {
  children: PropTypes.any.isRequired,
};

LaunchDarklyUpdater.defaultProps = {};

export default LaunchDarklyUpdater;
