/**
 *
 * Home
 *
 */

import FormattedHelmetTitle from 'components/FormattedHelmetTitle';
import HomeView from 'components/HomeView';
import Loader from 'components/Loader';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import Config from 'config';
import {
  LD_FEATURE_ACCOUNT_SETUP,
  LD_FEATURE_CASE_SUBMISSION,
  LD_FEATURE_ELIGIBILITY_CHECK,
} from 'constants/launchDarklyFlags';
import { SETUP_PAGE } from 'constants/pageIdentifiers';
import { selectAccountProfileComplete, selectAccountQuestionSet } from 'containers/Auth/AuthRouter/selectors';
import { fetchHomeDataAction } from 'containers/Home/actions';
import { selectHomeData, selectHomeLoading, selectPatientCaseTemplates } from 'containers/Home/selectors';
import { fetchMemberEligibilitiesAction } from 'containers/MemberProfile/actions';
import { selectMemberEligibility, selectMemberEligibilityFetched } from 'containers/MemberProfile/selectors';
import useAuthenticated from 'hooks/useAuthenticated';
import useMember from 'hooks/useMember';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEdiEnabled, getMemberEligibilityExpired } from 'utils/localStorageHelper';
import { getUnauthenticatedRedirectRoute } from 'utils/redirects';
import messages from './messages';

export const Home = () => {
  const isAuthenticated = useAuthenticated();
  const member = useMember();
  const flags = useFlags();
  const accountQuestionSet = useSelector(selectAccountQuestionSet());
  const accountProfileComplete = useSelector(selectAccountProfileComplete());
  const memberEligibilityExpired = getMemberEligibilityExpired();
  const ediEnabled = getEdiEnabled();
  const patientCases = useSelector(selectHomeData());
  const patientCasesLoading = useSelector(selectHomeLoading());
  const patientCaseTemplates = useSelector(selectPatientCaseTemplates());
  const memberEligibility = useSelector(selectMemberEligibility());
  const memberEligibilityFetched = useSelector(selectMemberEligibilityFetched());
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && member) {
      dispatch(fetchHomeDataAction());
      if (flags[LD_FEATURE_ELIGIBILITY_CHECK] && !memberEligibilityFetched) {
        dispatch(fetchMemberEligibilitiesAction());
      }
    }
  }, [member, isAuthenticated, dispatch, memberEligibilityFetched, flags]);

  // poll for home data
  useEffect(() => {
    const interval = setInterval(() => {
      if (isAuthenticated && member) {
        dispatch(fetchHomeDataAction());
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [member, isAuthenticated, dispatch]);

  if (!isAuthenticated) {
    // pass location state along for redirect
    return (
      <RedirectWithQuery
        push
        to={{
          pathname: getUnauthenticatedRedirectRoute(location.state?.redirectTo, flags),
          state: location.state,
          search: location.state?.redirectTo ? `redirectTo=${location.state.redirectTo}` : '',
        }}
      />
    );
  }

  if (!member || !accountQuestionSet) {
    return <Loader />;
  }

  if (flags[LD_FEATURE_ACCOUNT_SETUP]) {
    if (accountQuestionSet) {
      if (!accountProfileComplete && !accountQuestionSet?.completed) {
        return <RedirectWithQuery push to={SETUP_PAGE} />;
      }
    }
  }

  if (flags[LD_FEATURE_CASE_SUBMISSION]) {
    return (
      <>
        <FormattedHelmetTitle
          {...messages.documentTitle}
          values={{
            company: Config.companyName,
            documentTitle: Config.documentTitle,
          }}
        />

        <HomeView
          patientCasesLoading={patientCasesLoading}
          patientCases={patientCases}
          patientCaseTemplates={patientCaseTemplates}
          member={member}
          ediEnabled={ediEnabled}
          memberEligibility={memberEligibility}
          memberEligibilityExpired={memberEligibilityExpired}
          accountProfileComplete={accountProfileComplete}
        />
      </>
    );
  }

  return null;
};

Home.propTypes = {};

export default Home;
