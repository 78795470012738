import Navigation from 'components/Navigation';
import { RedirectWithQuery } from 'components/RedirectWithQueryParams';
import { HOME_PAGE } from 'constants/pageIdentifiers';
import { selectAccountProfileComplete, selectAccountQuestionSet } from 'containers/Auth/AuthRouter/selectors';
import { getQuestionSet } from 'containers/Questions/actions';
import { ACCOUNT_QUESTION_SET_TYPE } from 'containers/Questions/constants';
import { AuthProvider } from 'contexts/Auth.context';
import useAuthenticated from 'hooks/useAuthenticated';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { ScrollToTop } from 'utils/ScrollToTop';
import { getAuthToken } from 'utils/localStorageHelper';

/**
 * Children should all be Route components
 * Changes them to a redirect if they need it
 * @param children
 * @param isAuthenticated
 * @return {?*|Array|*[]}
 */
const getChildRoutes = (children, isAuthenticated) => {
  return React.Children.map(children, (child, i) => {
    const Component = (_props) => {
      const componentPath = child.props.path;
      const Co = child.props.component;

      Co.displayName = `AuthRoute(${componentPath})`;

      // if we are authenticated or do not need auth for this route, we add the context to that component
      // if not, that component becomes a redirect to login
      return isAuthenticated || child.props.noAuth ? (
        <Navigation>
          <Co {...(_props || {})} />
        </Navigation>
      ) : (
        <RedirectWithQuery
          to={{
            pathname: HOME_PAGE,
            state: {
              redirectTo: `${encodeURI(`${window.location.pathname}${window.location.search}`)}`,
            },
          }}
        >
          {child.props.noAuth && child.props.noAuth.buttonText}
        </RedirectWithQuery>
      );
    };
    return child
      ? {
          ...child,
          key: `ar${i}`,
          props: {
            ...child.props,
            component: Component,
          },
        }
      : null;
  });
};

export const AuthRouter = ({ children }) => {
  const isAuthenticated = useAuthenticated();
  const accountProfileComplete = useSelector(selectAccountProfileComplete());
  const accountQuestionSet = useSelector(selectAccountQuestionSet());
  const [AuthenticatedRoutes, setAuthenticatedRoutes] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && undefined === accountProfileComplete && !accountQuestionSet) {
      dispatch(getQuestionSet({ type: ACCOUNT_QUESTION_SET_TYPE }));
    }
  }, [accountProfileComplete, accountQuestionSet, isAuthenticated, dispatch]);

  useEffect(() => {
    window.scrollToTop();
  }, [location]);

  const onStorageUpdate = useCallback(() => {
    const token = getAuthToken();
    if (!token) {
      setCtx({
        isAuthenticated: false,
      });
    }
  }, []);

  // listen for changes to localstorage
  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return window.removeEventListener('storage', onStorageUpdate);
  }, [onStorageUpdate]);

  useEffect(() => {
    setAuthenticatedRoutes(getChildRoutes(children, isAuthenticated));
  }, [isAuthenticated]);

  const ctx = useMemo(() => {
    return {
      isAuthenticated,
    };
  }, [isAuthenticated]);

  return (
    <AuthProvider value={ctx}>
      <ScrollToTop />
      <Switch>{AuthenticatedRoutes}</Switch>
    </AuthProvider>
  );
};

export default AuthRouter;

AuthRouter.propTypes = {
  children: PropTypes.any,
};

AuthRouter.whyDidYouRender = true;
