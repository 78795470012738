import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Data = function () {
  this.data = [];
  this.push = (data) => {
    this.data = this.data.splice(0, 10);
    this.data.unshift(data);
  };
};

window.eventDebug = () => {
  window.tpDataLayer = new Data();
};
window.debugEvents = window.location.search.indexOf('debugevents') > -1;
window.debugEventsSmall = window.location.search.indexOf('debugevents=small') > -1;
if (window.debugEvents) eventDebug();

const Container = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.4);
  min-height: 100px;
  max-height: 100vh;
  max-height: --webkit-fill-available;
  width: 240px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  z-index: 100000;
  overflow-y: hidden;
  transition: background 0.3s linear;
  pointer-events: none;

  h2 {
    color: var(--primary01);
    font-size: 14px;
  }
  div {
    border: 1px solid var(--primary01);
  }
  h3 {
    color: var(--primary02);
    font-size: 10px;
    span {
      float: right;
      width: 10px;
      height: 10px;
    }
  }
  p {
    color: var(--neutral07);
    font-size: 8px;
    white-space: pre-wrap;
  }
`;
const EventData = styled.div`
  background: ${({ type }) => {
    switch (type) {
      case 'identify':
        return 'rgba(100, 255, 100, 0.4)';
      case 'error':
        return 'rgba(255, 100, 100, 0.4)';
      default:
        return 'rgba(255, 255, 255, 0.4)';
    }
  }};
  ${({ collapsed }) => collapsed && 'overflow:hidden;height:12px;'};
  p {
    border-top: 1px solid var(--g-01-25);
  }
`;
const EventItem = ({ datum: [type, event, data] }) => {
  return (
    <EventData type={type}>
      <h3>
        [{type}] {event.event_name}
      </h3>
      <p>{window.debugEventsSmall ? event.event_name : JSON.stringify(event, null, 2)}</p>
      <p>{window.debugEventsSmall ? JSON.stringify(data) : JSON.stringify(data, null, 2)}</p>
    </EventData>
  );
};
const EventDebugger = () => {
  if (!window.debugEvents) return null;
  const [mapData, setMapData] = useState(window.tpDataLayer?.data);
  useEffect(() => {
    setInterval(() => {
      setMapData(window.tpDataLayer.data);
    }, 500);
  }, []);
  return (
    <Container>
      <h2>Event Debugger</h2>
      {mapData.map((d) => (
        <EventItem key={d[3]} datum={d} />
      ))}
    </Container>
  );
};

export default EventDebugger;
