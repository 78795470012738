/*
 *
 * login reducer
 *
 */

import {
  CLEAR_ERRORS,
  DEFAULT_ACTION,
  ENABLE_MFA,
  FETCH_COMPANY_CONFIG_SUCCESS,
  FETCH_MEMBER,
  FETCH_MEMBER_FAILURE,
  FETCH_MEMBER_SUCCESS,
  GOOGLE_SIGNUP_REQUEST,
  GOOGLE_SSO_REQUEST,
  LOAD_PROVIDER_OPTIONS_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SUCCESS,
  MFA_LOGIN,
  PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE,
  PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS,
  RESET_REDUCER,
  SIGN_OUT_USER,
  SIGNUP_REQUEST,
  SIGNUP_REQUEST_FAILURE,
  USER_LOGIN,
} from 'containers/Auth/AuthRouter/constants';
import { initialState } from 'containers/Auth/AuthRouter/initialState';
import {
  ACCOUNT_QUESTION_SET_TYPE,
  FETCH_QUESTION_SETS_SUCCESS,
  GET_QUESTION_SET_SUCCESS,
  MEMBER_QUESTION_SET_TYPE,
  PATCH_QUESTION_SET_COMPLETE_SUCCESS,
  PATCH_QUESTION_SET_SUCCESS,
} from 'containers/Questions/constants';

const iState = initialState();

function loginReducer(state = iState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return { ...state, errors: null };

    case RESET_REDUCER:
      return initialState();

    case ENABLE_MFA:
      return { ...state, mfaEnabled: true };

    case LOGIN_REQUEST:
      return {
        ...state,
        authenticationComplete: false,
        errors: null,
        isLoading: true,
        loginError: null,
      };

    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        authenticationComplete: true,
        authToken: action.payload.auth_token,
        errors: null,
        isLoading: false,
        isAuthenticated: true,
        loggedOut: false,
        loginError: null,
        redirectToDashboard: true,
        sessionTimeOut: action.payload.ttl,
        loadingSSO: false,
      };

    case SIGN_OUT_USER:
      return { ...initialState(), member: null, authToken: null };

    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        loginError: action.error,
        modalOpen: true,
        password: '',
        page: 1,
        loadingSSO: false,
      };

    case MFA_LOGIN:
      return {
        ...state,
        isLoading: true,
        mfaLoginSent: true,
        errors: null,
      };

    case CLEAR_ERRORS:
      return { ...state, errors: null, loginError: null, signupError: null };

    // login
    case USER_LOGIN:
      return { ...state, isAuthenticated: true };

    case SIGNUP_REQUEST:
      return {
        ...state,
        email: action.payload.email,
        password: action.payload.password,
        isLoading: true,
        authenticationComplete: false,
        errors: null,
        signupError: null,
      };

    case GOOGLE_SIGNUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        authenticationComplete: false,
        errors: null,
        signupError: null,
      };
    case GOOGLE_SSO_REQUEST:
      return {
        ...state,
        loadingSSO: true,
        isLoading: true,
        authenticationComplete: false,
        errors: null,
        signupError: null,
      };
    case SIGNUP_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        signupError: action.error,
      };

    case FETCH_MEMBER:
      return {
        ...state,
        memberLoading: true,
      };

    case PATCH_MEMBER_MEDICAL_INFORMATION_SUCCESS:
    case FETCH_MEMBER_SUCCESS:
      const member = action.payload;
      if (!member) return state;
      return {
        ...state,
        memberLoading: false,
        member,
      };

    case PATCH_MEMBER_MEDICAL_INFORMATION_FAILURE:
    case FETCH_MEMBER_FAILURE:
      return {
        ...state,
        memberLoading: false,
      };

    case LOAD_PROVIDER_OPTIONS_SUCCESS:
      return { ...state, providerOptions: action.payload };

    case GET_QUESTION_SET_SUCCESS:
      return onGetQuestionSetSuccess(state, action);

    case FETCH_QUESTION_SETS_SUCCESS:
      return onFetchQuestionSetCompleteSuccess(state, action);

    case PATCH_QUESTION_SET_COMPLETE_SUCCESS:
      return onPatchQuestionSetCompleteSuccess(state, action);

    case PATCH_QUESTION_SET_SUCCESS:
      if (!action.payload.completed) return state;
      return onPatchQuestionSetCompleteSuccess(state, action);

    case FETCH_COMPANY_CONFIG_SUCCESS:
      const companyConfig = action.payload;
      if (!companyConfig) return state;
      return {
        ...state,
      };

    default:
      return state;
  }
}

const onPatchQuestionSetCompleteSuccess = (state, action) => {
  const obj = {};
  const st = { ...state };
  const type = action.payload.type || action.payload.question_set_type;
  if (type === ACCOUNT_QUESTION_SET_TYPE) {
    st.accountProfileComplete = true;
    st.accountQuestionSet = action.payload?.id ? action.payload : action.payload.data;
  } else if (type === MEMBER_QUESTION_SET_TYPE) {
    obj.member_profile_complete = true;
  }
  return { ...st, member: { ...(state.member || {}), ...obj } };
};

const onGetQuestionSetSuccess = (state, action) => {
  const obj = {};
  const set = action.payload?.length ? action.payload[0] : action.payload;
  if (!set) return state;
  const st = { ...state };
  if (set.question_set_type === ACCOUNT_QUESTION_SET_TYPE) {
    st.accountProfileComplete = set.completed;
    st.accountQuestionSet = set;
  } else if (set.question_set_type === MEMBER_QUESTION_SET_TYPE && set.completed) {
    obj.member_profile_complete = true;
  }
  return { ...st, member: { ...(state.member || {}), ...obj } };
};

const onFetchQuestionSetCompleteSuccess = (state, action) => {
  const obj = {};
  const set = action.payload?.data?.length ? action.payload.data[0] : null;
  if (!set) return state;
  const st = { ...state };
  if (action.payload.type === ACCOUNT_QUESTION_SET_TYPE && set.completed) {
    st.accountProfileComplete = true;
    st.accountQuestionSet = set;
  } else if (action.payload.type === MEMBER_QUESTION_SET_TYPE && set.completed) {
    obj.member_profile_complete = true;
  }
  return { ...st, member: { ...(state.member || {}), ...obj } };
};

export default loginReducer;
