import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowLeft,
  faBars,
  faCamera,
  faCheck,
  faChevronRight,
  faCog,
  faComment,
  faHandHoldingMedical,
  faPlusSquare,
  faQuestionCircle,
  faRedo,
  faSignOutAlt,
  faStar,
  faTimes,
  faUserAlt,
  faUserCircle,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);
library.add(faBars);
library.add(faCamera);
library.add(faCheck);
library.add(faChevronRight);
library.add(faHandHoldingMedical);
library.add(faPlusSquare);
library.add(faQuestionCircle);
library.add(faRedo);
library.add(faSignOutAlt);
library.add(faStar);
library.add(faTimes);
library.add(faUserAlt);
library.add(faUserCircle);
library.add(faVideo);
library.add(faCog);
library.add(faComment);
