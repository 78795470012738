import {
  COMPLETE_CARE_TEMPLATE_BASE_NAME,
  DEFAULT_TEMPLATE_TYPE,
  SCHEDULED_VIDEO_TEMPLATE_BASE_NAME,
  SPECIFIC_ISSUE_TEMPLATE_BASE_NAME,
  TRIAGE_CASE_TYPE,
  VIDEO_TEMPLATE_BASE_NAME,
} from 'constants/cases';
import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the getCare state domain
 */

const selectGetCareDomain = (state = initialState()) => state.getCare;

/**
 * Other specific selectors
 */
const selectPatientCases = () => createSelector(selectGetCareDomain, (substate) => substate.patientCases.data);

const selectPatientCasesFetched = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.fetched);

const selectPatientCaseTemplatesFetched = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCaseTemplates.fetched);

const selectPatientCase = (id) =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.data?.find((pc) => pc.id === id));

const selectPatientCasesLoading = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.loading);

const selectPatientCasesError = () => createSelector(selectGetCareDomain, (substate) => substate.patientCases.error);

const selectNewPatientCase = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.newCreatedCase);

const selectNewPatientCaseLoading = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.newCaseLoading);

const selectNewPatientCaseError = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCases.newCaseError);

export const selectGetPatientCaseTemplateError = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCaseTemplates.error);

const selectPatientCaseTemplates = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCaseTemplates.data);

const selectRegions = (countries) =>
  createSelector(selectGetCareDomain, (substate) => {
    if (!countries) return substate.regions.data;
    return substate.regions.data.filter((region) => countries.indexOf(region.country) > -1);
  });

const selectRegionsLoading = () => createSelector(selectGetCareDomain, (substate) => substate.regions.loading);

const selectPatientCaseTemplatesLoading = () =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCaseTemplates.loading);

const selectTriagePatientCaseTemplate = () =>
  createSelector(selectGetCareDomain, (substate) =>
    substate.patientCaseTemplates.data?.find(
      (d) => d.template_base_name === TRIAGE_CASE_TYPE && d.template_type === DEFAULT_TEMPLATE_TYPE,
    ),
  );

export const selectCompleteCareTemplate = () =>
  createSelector(selectGetCareDomain, (substate) =>
    substate.patientCaseTemplates.data?.find((d) => d.template_base_name === COMPLETE_CARE_TEMPLATE_BASE_NAME),
  );

export const selectTemplate = (templateBaseName) => {
  return createSelector(selectGetCareDomain, (substate) =>
    substate.patientCaseTemplates.data?.find((d) => d.template_base_name === templateBaseName),
  );
};

export const selectSpecificIssueTemplate = () =>
  createSelector(selectGetCareDomain, (substate) =>
    substate.patientCaseTemplates.data?.find((d) => d.template_base_name === SPECIFIC_ISSUE_TEMPLATE_BASE_NAME),
  );

export const selectVideoCaseTemplate = () =>
  createSelector(selectGetCareDomain, (substate) => {
    return substate.patientCaseTemplates.data?.find((d) => d.template_base_name === VIDEO_TEMPLATE_BASE_NAME);
  });
export const selectScheduledVideoCaseTemplate = () =>
  createSelector(selectGetCareDomain, (substate) => {
    return substate.patientCaseTemplates.data?.find((d) => d.template_base_name === SCHEDULED_VIDEO_TEMPLATE_BASE_NAME);
  });

export const selectPatientCaseTemplateById = (id) =>
  createSelector(selectGetCareDomain, (substate) => substate.patientCaseTemplates.data?.find((d) => d.id === id));

/**
 * Default selector used by GetCare
 */

const makeSelectGetCare = () => createSelector(selectGetCareDomain, (substate) => substate);

export default makeSelectGetCare;
export {
  selectGetCareDomain,
  selectPatientCase,
  selectPatientCases,
  selectPatientCasesFetched,
  selectPatientCaseTemplatesFetched,
  selectNewPatientCaseLoading,
  selectNewPatientCaseError,
  selectPatientCasesLoading,
  selectPatientCaseTemplates,
  selectPatientCaseTemplatesLoading,
  selectTriagePatientCaseTemplate,
  selectRegions,
  selectRegionsLoading,
  selectPatientCasesError,
};
