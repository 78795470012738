import { APIError } from '@oraleye/frontend-modules-utils';
import { CREATE_ACCOUNT_PAGE, LOGIN_PAGE, LOGOUT_PAGE } from 'constants/pageIdentifiers';
import { parse, stringify } from 'query-string';
import {
  clearUserLocalStorageData,
  getAuthToken,
  getCampaignId,
  getCountry,
  getLocale,
} from 'utils/localStorageHelper';
import { clearUserSessionStorageData } from 'utils/sessionStorageHelper';
import { unauthRoutes } from './urls';

export function getError(data) {
  return (
    data && data.errors && data.errors.reduce((memo, err) => `${memo ? memo + '\n' : ''}${err.external_message}`, '')
  );
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 202 ||
    response.status === 404 ||
    response.status === 503
  ) {
    return Promise.resolve(null);
  }
  return response.json().catch((e) => null);
}

export const redirectToLogin = () => {
  const currentRedirect = parse(window.location.search);
  const newRedirect = {
    redirectTo: `${window.location.pathname}${window.location.search}`,
    sessionExpired: 1,
    ...currentRedirect,
  };
  const newRedirectString = newRedirect.redirectTo === LOGOUT_PAGE ? '' : `?${stringify(newRedirect)}`;
  window.location.replace(`${LOGIN_PAGE}${newRedirectString}`);
};

export const redirectToSignup = () => {
  window.location = `${CREATE_ACCOUNT_PAGE}?redirectTo=${encodeURI(
    `${window.location.pathname}${window.location.search}`,
  )}&sessionExpired=1`;
};

export const redirectToQuipLogin = () => {
  const redirectRoute = parse(window.location.search)?.redirectTo || window.location.state?.redirectTo;
  const redirect = redirectRoute ? `${window.location.origin}${redirectRoute}` : window.location.origin;
  window.location.replace(
    `${window.env.QUIP_LOGIN_URL}?redirectParams=${encodeURIComponent(
      `callback=${redirect}&ci=${getCampaignId() || 'quip'}`,
    )}`,
  );
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response, url) {
  if (!response.ok) {
    window.Rollbar?.error(`Reponse !ok: ${response.status}`, { url });
  }

  if (response.status === 204) {
    return null;
  }

  if (response.status === 403 || response.status === 401) {
    return response.json().then((res) => {
      if (res.errors.length && res.errors.some((e) => e.integration_type === 'quip')) {
        if (res.errors.some((e) => e.internal_id === 2427)) {
          alert('Account conflict detected. Please contact the support team to rectify.');
        } else {
          redirectToQuipLogin();
        }
        return null;
      }
      return Promise.reject(response.status);
    });
  }

  if (response.status === 401 && window.location.pathname.indexOf('/login') === -1) {
    clearUserLocalStorageData();
    clearUserSessionStorageData();

    if (response.url.indexOf('auth') > -1) {
      return;
    }
    redirectToLogin();
    return null;
  }
  if (response.status === 404) {
    return response.json().then((res) => {
      if (res.errors?.length) throw new APIError(res);
      return res;
    });
  }

  if (response.status >= 200 && response.status < 300) {
    return parseJSON(response);
  }

  // Get value from promise and throw error with custom messgae from backend
  return parseJSON(response).then((data) => {
    const error = new APIError({
      message: getError(data) || 'An error has occurred',
      ...data,
      status: response.status,
    });
    console.error(error); // eslint-disable-line no-console
    return Promise.reject(error);
  });
}

export const isUnAuthRoute = (url, options) =>
  unauthRoutes.some((u) => (u instanceof RegExp ? u.test(url) : u[options.method] && u[options.method].test(url)));

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function request(url, options, opts = { parseResponse: true }) {
  const fullURL = `${window.env.TP_API_URL}/${url}`;
  if (!window.env.TP_API_KEY) {
    window.Rollbar?.error('Missing API Key!', {
      apiKey: '' + window.env.TP_API_KEY,
      url,
    });
  }
  options.headers.set('X-Platform', 'member');
  options.headers.set('X-Locale', getLocale());
  options.headers.set('X-Country', getCountry());
  options.headers.set('X-Client-Version', process.env.VERSION);
  options.headers.set('X-Api-Key', window.env.TP_API_KEY);
  const token = getAuthToken();
  if (!token && !isUnAuthRoute(url, options)) {
    redirectToLogin();
    return Promise.resolve(null);
  }
  if (token) {
    options.headers.set('X-Auth-Token', token);
  }

  if (options.method !== 'DELETE') {
    options.headers.set('Content-Type', 'application/json');
  }

  const ret = fetch(fullURL, options);
  if (opts.parseResponse) {
    return ret.then((response) => checkStatus(response, url));
  }

  return ret;
}

export default request;
