import { HEIGHT_TYPE, WEIGHT_TYPE } from 'components/QuestionCard/constants';
import { isHeightWeightCard, isHiddenDependantOnDob } from 'components/QuestionCard/helpers';
import Config from 'config';
import { fetchMemberAction } from 'containers/Auth/AuthRouter/actions';
import { FETCH_MEMBER_SUCCESS } from 'containers/Auth/AuthRouter/constants';
import { selectMember } from 'containers/Auth/AuthRouter/selectors';
import {
  checkQuestionSetReadyForCompletion,
  checkQuestionSetReadyForCompletionFailure,
  checkQuestionSetReadyForCompletionSuccess,
  fetchQuestionCardsFailure,
  fetchQuestionCardsSuccess,
  fetchQuestionSetsFailure,
  fetchQuestionSetsSuccess,
  getQuestionSetFailure,
  getQuestionSetSuccess,
  patchQuestionCardFailure,
  patchQuestionCardSuccess,
  patchQuestionSetCompleteFailure,
  patchQuestionSetCompleteSuccess,
  patchQuestionSetFailure,
  patchQuestionSetSuccess,
  updateQuestionSetAnswer,
  updateQuestionSetAnswerFailure,
} from 'containers/Questions/actions';
import {
  ACCOUNT_QUESTION_SET_TYPE,
  CASE_QUESTION_SET_TYPE,
  CHECK_SET_READY_FOR_COMPLETION,
  FETCH_QUESTION_CARDS,
  FETCH_QUESTION_CARDS_SUCCESS,
  FETCH_QUESTION_SETS,
  GET_QUESTION_SET,
  MEMBER_QUESTION_SET_TYPE,
  PATCH_QUESTION_CARD,
  PATCH_QUESTION_SET,
  PATCH_QUESTION_SET_COMPLETE,
  REPORT_FEEDBACK_SET_TYPE,
} from 'containers/Questions/constants';
import { selectCurrentQuestionSet, selectCurrentQuestionSetCards } from 'containers/Questions/selectors';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import request from 'utils/request';
import { retryRequest } from 'utils/retryRequest';
import {
  accountQuestionSetUrl,
  caseQuestionSetUrl,
  memberQuestionSetUrl,
  questionCardsUrl,
  reportFeedbackQuestionSet,
} from 'utils/urls';

const getQuestionSetUrl = (type) => {
  switch (type) {
    case ACCOUNT_QUESTION_SET_TYPE:
      return accountQuestionSetUrl;
    case MEMBER_QUESTION_SET_TYPE:
      return memberQuestionSetUrl;
    case CASE_QUESTION_SET_TYPE:
      return caseQuestionSetUrl;
    case REPORT_FEEDBACK_SET_TYPE:
      return reportFeedbackQuestionSet;
    default:
      throw 'No type included';
  }
};

function* fetchQuestionSets({ payload }) {
  const method = 'GET';
  let url = getQuestionSetUrl(payload.type);
  if (payload.patientCaseId) {
    url = `${url}?patient_case_id=${payload.patientCaseId}`;
  }
  // ADD FILTER FOR REPORT ID
  if (url.includes(REPORT_FEEDBACK_SET_TYPE)) {
    url = `${url}${payload.patientCaseId ? '&' : '?'}report_id=${payload?.reportId}`;
  }
  const endpoint = `${url}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(retryRequest, endpoint, requestData, (data) => data.length);
    yield fetchQuestionCards({ payload });
    yield put(
      fetchQuestionSetsSuccess({
        data: response,
        type: payload.type,
        member_id: payload.member_id,
      }),
    );
  } catch (e) {
    yield put(
      fetchQuestionSetsFailure(
        {
          error: e,
          type: payload.type,
          member_id: payload.member_id,
        },
        payload,
      ),
    );
  }
}

function* getQuestionSetId(id = '', type) {
  const method = 'GET';
  const url = getQuestionSetUrl(type);
  const endpoint = `${url}/${id}`;
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  return yield call(retryRequest, endpoint, requestData, (data) => data.length);
}

function* getQuestionSet({ payload }) {
  try {
    const response = yield getQuestionSetId(payload.id, payload.type);
    yield put(getQuestionSetSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getQuestionSetFailure(e, payload));
  }
}

export function* patchQuestionSet({ payload }) {
  const { type, id, ...body } = payload;
  const method = 'PATCH';
  const url = getQuestionSetUrl(payload.type);
  const endpoint = `${url}/${payload.id}`;
  const headers = new Headers();

  const requestData = {
    method,
    headers,
    body: JSON.stringify(body),
    mode: 'cors',
  };
  try {
    const response = yield call(request, endpoint, requestData);
    yield put(patchQuestionSetSuccess(response));
    return response;
  } catch (e) {
    console.error(e);
    yield put(patchQuestionSetFailure(e, payload));
  }
}

function* patchOptionalCardsWithBlankAnswers() {
  const set = yield select(selectCurrentQuestionSet);
  const cards = yield select(selectCurrentQuestionSetCards);
  const firstPath = set.path[0];
  const path = [firstPath];
  let current = cards[firstPath];
  const cardsToPatchEmpty = [];
  while (current) {
    let selectedAnswer = current.answers.find((ans) => ans.selected);
    const isOptional = current.question.optional;
    if (
      isOptional &&
      (!selectedAnswer ||
        (selectedAnswer && (selectedAnswer.answer_value === undefined || selectedAnswer.answer_value === '')))
    ) {
      cardsToPatchEmpty.push(current);
      selectedAnswer = current.answers[0];
    }
    const nextCardId = selectedAnswer.next_question_card_id;
    if (current && nextCardId && !current.error) {
      path.push(nextCardId);
    }
    if (current && nextCardId && !current.error) {
      path.push(nextCardId);
      current = cards[nextCardId];
    } else if (current && !nextCardId && !current.error) {
      current = null;
    } else {
      current = null;
    }
  }
  yield all(
    cardsToPatchEmpty.map((card) =>
      call(patchQuestionCard, {
        payload: {
          card: {
            ...card,
            answers: card.answers.map((ans, i) => {
              if (i == 0) {
                ans.answer_value = ans.answer_type === HEIGHT_TYPE || ans.answer_type === WEIGHT_TYPE ? 0 : '-';
                ans.selected = i == 0;
              }
              return ans;
            }),
          },
          answer_value: '-',
          question_and_answer_id: '',
          type: set.question_set_type,
        },
      }),
    ),
  );
}

export function* patchQuestionSetComplete({ payload }) {
  try {
    const { redirect, ...body } = payload;
    yield patchOptionalCardsWithBlankAnswers();
    const response = yield patchQuestionSet({
      payload: { ...body, completed: true },
    });
    if (!response?.completed) {
      throw (
        'There was a problem submitting your questions. Please try again.' +
        '\nIf the problem persists, please contact ' +
        Config.supportEmail
      );
    }
    if (body.type === CASE_QUESTION_SET_TYPE) {
      yield put(patchQuestionSetCompleteSuccess(response || payload));
    } else {
      yield put(fetchMemberAction());
      yield take(FETCH_MEMBER_SUCCESS);
      yield put(patchQuestionSetCompleteSuccess(response));
    }
  } catch (e) {
    console.error(e);
    yield put(patchQuestionSetCompleteFailure(e, payload));
  }
}

function* fetchQuestionCards({ payload }) {
  const method = 'GET';
  let url = `${questionCardsUrl}?limit=150&question_set_type=${payload.type}`;
  if (payload.patientCaseId && payload.type !== 'account' && payload.type !== 'member') {
    url = `${url}&patient_case_id=${payload.patientCaseId}`;
  }
  const headers = new Headers();
  const requestData = {
    method,
    headers,
    mode: 'cors',
  };
  try {
    const response = yield call(request, url, requestData);
    yield put(fetchQuestionCardsSuccess({ data: response, type: payload.type }));
  } catch (e) {
    yield put(fetchQuestionCardsFailure(e));
  }
}

function* patchQuestionCard({ payload }) {
  let response;

  try {
    const { card } = payload;
    let value;
    // check for empty answer
    if (card.answers.length === 1) {
      const ans = card.answers[0];
      value = typeof ans.answer_value === 'string' ? `${ans.answer_value}`.trim() : ans.answer_value ?? '';
      if ('' === value && ans.selected && !card.question?.optional) {
        throw new Error('Answer is required');
      }
      card.answers[0].answer_value = value;
    }

    const method = 'PATCH';
    const endpoint = `${questionCardsUrl}/${card.id}`;
    const headers = new Headers();
    const body = {
      id: card.id,
      answers: card.answers,
      client_updated_at: new Date(),
    };
    const requestData = {
      method,
      headers,
      body: JSON.stringify(body),
      mode: 'cors',
    };
    response = yield call(request, endpoint, requestData);
    yield put(patchQuestionCardSuccess({ ...payload, card: response }));
    try {
      yield put(updateQuestionSetAnswer({ ...payload, card: response }));
      yield put(checkQuestionSetReadyForCompletion({ ...payload, card: response }));
    } catch (e) {
      yield put(updateQuestionSetAnswerFailure(e, payload));
    }
  } catch (e) {
    yield put(patchQuestionCardFailure(e, payload));
  }
}

function* checkQuestionSetReadyComplete({ payload }) {
  let setId;
  let path;
  try {
    const member = yield select(selectMember());
    const set = yield select(selectCurrentQuestionSet);
    const cards = yield select(selectCurrentQuestionSetCards);
    if (!set || !cards) return;
    setId = set.id;
    let isReadyComplete = false;
    path = set?.path || [];

    if (set.complete || !set.path) {
      isReadyComplete = true;
    } else if (set && cards) {
      const firstPath = set.path[0];
      path = [firstPath];
      let current = cards[firstPath];
      let dobCard = Object.values(cards).find(
        (c) => c.question.text.indexOf('date of birth') > -1 && c.answers[0].selected,
      );
      while (current) {
        let selectedAnswer = current.answers.find((ans) => ans.selected);
        let isOptional = current.question.optional;

        if (isHeightWeightCard(current)) {
          isOptional = isHiddenDependantOnDob(current, dobCard, member);
          if (selectedAnswer) {
            const a = parseInt(selectedAnswer.answer_value);
            if (a <= 0 || isNaN(a)) {
              selectedAnswer = null;
            }
          }
        }

        if (!selectedAnswer && !isOptional) {
          break;
        }

        if (isOptional && !selectedAnswer) {
          selectedAnswer = current.answers[0];
        }
        const nextCardId = selectedAnswer.next_question_card_id;
        if (current && nextCardId && !current.error) {
          path.push(nextCardId);
          current = cards[nextCardId];
        } else if (current && !nextCardId && !current.error) {
          current = null;
          isReadyComplete = true;
        } else {
          current = null;
        }
      }
    }

    if (isReadyComplete) {
      yield put(
        checkQuestionSetReadyForCompletionSuccess({
          ...payload,
          setId: set.id,
          path,
        }),
      );
    } else {
      yield put(
        checkQuestionSetReadyForCompletionFailure({
          ...payload,
          setId: set.id,
          path,
        }),
      );
    }
  } catch (e) {
    console.error(e);
    yield put(
      checkQuestionSetReadyForCompletionFailure({
        ...payload,
        setId: setId,
        path,
      }),
    );
  }
}

const checkMatricesEqual = (m1, m2) => {
  return m1.every((a1, i) => a1.every((a2, j) => m2[i][j] === a2));
};

export default function* questionsSaga() {
  yield takeEvery(FETCH_QUESTION_CARDS, fetchQuestionCards);
  yield takeEvery(FETCH_QUESTION_CARDS_SUCCESS, checkQuestionSetReadyComplete);
  yield takeEvery(PATCH_QUESTION_CARD, patchQuestionCard);
  yield takeEvery(GET_QUESTION_SET, getQuestionSet);
  yield takeEvery(FETCH_QUESTION_SETS, fetchQuestionSets);
  yield takeEvery(PATCH_QUESTION_SET, patchQuestionSet);
  yield takeEvery(PATCH_QUESTION_SET_COMPLETE, patchQuestionSetComplete);
  yield takeEvery(CHECK_SET_READY_FOR_COMPLETION, checkQuestionSetReadyComplete);
}
