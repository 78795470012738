import { Select } from '@oraleye/frontend-modules-components';
import Button from 'components/Button';
import Loader from 'components/Loader';
import { ReactComponent as ExclamationIcon } from 'images/icon-error-exclamation-circle.svg';
import { ReactComponent as MicIcon } from 'images/video/unMuteAudio.svg';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { setSelectedDevice } from './helpers';

const PidsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  svg {
    width: 24px;
    height: 24px;
    color: var(--primary01);
  }
  .pid {
    flex: 1;
    height: 10px;
    display: inline-block;
    margin: 5px;
  }
`;

const colorPids = (vol) => {
  const allPids = [...document.querySelectorAll('.pid')];
  const numberOfPidsToColor = Math.round(vol / 10);
  const pidsToColor = allPids.slice(0, numberOfPidsToColor);
  for (const pid of allPids) {
    pid.style.backgroundColor = '#e6e7e8';
  }
  for (const pid of pidsToColor) {
    pid.style.backgroundColor = '#69ce2b';
  }
};

export const AudioInputTest = ({ askForPermissions, devices, permission, selectedDeviceId: sid, onReady }) => {
  const [hasAudioStream, setHasAudioStream] = useState(false);
  const [selectedDeviceId, setSelectedDeviceId] = useState(sid);

  const audioStream = useRef();
  const scriptProcessorRef = useRef();

  const handleStartAudio = useCallback(async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: selectedDeviceId },
    });
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);
    const handleAudioProcess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      const arraySum = array.reduce((a, value) => a + value, 0);
      const average = arraySum / array.length;

      colorPids(average);
    };

    scriptProcessor.addEventListener('audioprocess', handleAudioProcess);
    audioStream.current = stream;
    scriptProcessorRef.current = scriptProcessor;
    setHasAudioStream(true);
  }, [selectedDeviceId]);

  const handleStopAudio = useCallback(() => {
    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect();
    }
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => {
        track.stop();
      });
      audioStream.current = null;
      setHasAudioStream(false);
    }
  }, []);

  const handleAudioInputChange = useCallback((event) => {
    const deviceId = event.target.value;
    setSelectedDevice('AudioInput', deviceId);
    setSelectedDeviceId(deviceId);
  }, []);

  const audioInputDeviceOptions = useMemo(
    () =>
      devices.map((device) => ({
        value: device.deviceId,
        label: device.label,
      })),
    [devices],
  );

  useEffect(() => {
    setSelectedDeviceId(sid);
  }, [sid]);

  useEffect(() => {
    if (hasAudioStream) {
      onReady?.();
    }
  }, [hasAudioStream, onReady]);

  useEffect(() => {
    if (permission?.state === 'denied') {
      handleStopAudio();
      onReady?.();
    }
  }, [permission, handleStopAudio, onReady]);

  // init
  useEffect(() => {
    handleStartAudio();
    return () => {
      handleStopAudio();
    };
  }, [handleStartAudio, handleStopAudio]);

  if (!permission) {
    return <Loader />;
  }

  return (
    <div>
      {permission.state === 'prompt' && (
        <div>
          <p>Audio permission is not granted.</p>
          <Button onClick={askForPermissions}>Grant Permission</Button>
        </div>
      )}

      {permission.state === 'denied' && (
        <div style={{ textAlign: 'center' }}>
          <span>
            <ExclamationIcon />
          </span>
          <h3>Audio permission is denied</h3>
          <p>
            You may have to grant permissions manually if you did not see a popup on this page. Click the information
            icon in the address bar, or check your browsers settings to see audio permissions.
          </p>
        </div>
      )}
      {permission.state === 'granted' && audioStream.current && (
        <div>
          <Form
            onSubmit={() => {}}
            render={() => (
              <Select
                name="audioInputDevices"
                options={audioInputDeviceOptions}
                initialValue={selectedDeviceId}
                placeholder="Select a audio input device"
                value={selectedDeviceId}
                onChange={handleAudioInputChange}
                trackingId={'audio-input-device'}
              />
            )}
          />

          <PidsWrapper>
            <MicIcon />
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
            <div className="pid"></div>
          </PidsWrapper>
        </div>
      )}
      {permission.state === 'granted' && !audioStream.current && <Loader />}
    </div>
  );
};
